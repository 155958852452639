<template>
  <graph
    height="136px"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import _ from 'lodash'
import Graph from '@/components/graph.vue'

export default {
  components: {
    Graph
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          group: 'sparklines',
          sparkline: {
            enabled: true
          },
          type: 'area'
        },
        labels: this.data.map(a => {
          const d = this.moment(a.date)
          return Date.UTC(d.get('year'), d.get('month'), d.get('date'))
        }),
        stroke: {
          curve: 'straight',
          width: 2
        },
        subtitle: {
          text: this.title,
          style: {
            fontSize: '14px'
          }
        },
        title: {
          text: this.formatCurrency(_(this.data).map(a => a.value).last(), this.currency),
          style: {
            fontSize: '20px'
          }
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          min: 0
        }
      }
    },
    series () {
      return [{
        name: '',
        data: this.data.map(a => a.value)
      }]
    }
  },
  props: {
    currency: String,
    data: Array,
    title: String
  }
}
</script>
